import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Outlet, Navigate } from 'react-router-dom';
import { api, pesan, Token, host, compressImageBase64, resizeImageBase64, submitForm } from './Modul';
import Logo from './assets/img/logo.png';
import LogoPanjang from './assets/img/logopanjang.png';
import Profile from './assets/img/profile.png';
import BG2 from './assets/img/bg-2.png';
import settingProfile from './assets/img/setting-profile.jpg';
import imgUpload from './assets/img/img-upload.png';
import './App.css';
import './Login.css';

// PAGE
import Dashboard from './page/Dashboard';
import KontenHome from './page/KontenHome';
import KontenProfile from './page/KontenProfile';
import KontenKelas from './page/KontenKelas';
import KontenKontak from './page/KontenKontak';
import KontenGaleri from './page/KontenGaleri';
import KontenFooter from './page/KontenFooter';
import KontenArtikel from './page/KontenArtikel';
import MasterAdmin from './page/MasterAdmin';
import MasterTeam from './page/MasterTeam';
import MasterGuru from './page/MasterGuru';
import MasterMurid from './page/MasterMurid';
import MasterWaliMurid from './page/MasterWaliMurid';
import MasterKelas from './page/MasterKelas';
import MasterJurusan from './page/MasterJurusan';
import MasterMataPelajaran from './page/MasterMataPelajaran';
import MenuArtikel from './page/MenuArtikel';
import MenuKategoriArtikel from './page/MenuKategoriArtikel';
import MenuKomentarArtikel from './page/MenuKomentarArtikel';
import MasterProduk from './page/MasterProduk';

function App() {
  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Menu: [],
        Group: [],
        CurePage: [],
        NamaLokasi: "Pusat",
        NamaUser: "Heru Prasetia",
        divSetting: [],
        detailSetting: [],
        clsLoading: "fas fa-save",
        btnSubmit: false,
        iconTheme: "fas fa-moon"
      };
    }

    async componentDidMount() {
      let sql = await api("menu", { act: "menu" });
      let Menu = [], Group = [
        { Nama: "Konten", Icon: "fas fa-images", Posisi: "konten", Active: false },
        { Nama: "Master", Icon: "fas fa-th-list", Posisi: "master", Active: false },
        { Nama: "Artikel", Icon: "fas fa-newspaper", Posisi: "artikel", Active: false },
      ];
      for (let menu of JSON.parse(sql.data.Menu)) {
        Menu.push(menu);
        for (let gg in Group) if (Group[gg].Posisi == menu.Posisi) Group[gg].Active = true;
      }
      let ColorTheme = localStorage.getItem("ColorTheme") || "light";
      if (ColorTheme == "dark") {
        document.body.classList.add('dark-theme');
        let cls = document.getElementsByClassName("table");
        for (let i = 0; i < cls.length; i++) {
          cls[i].classList.add("table-dark");
        }
        let clsOffCanvas = document.getElementsByClassName("offcanvas");
        for (let i = 0; i < clsOffCanvas.length; i++) {
          clsOffCanvas[i].classList.add("text-bg-dark");
        }
        let clsCard = document.getElementsByClassName("card");
        for (let i = 0; i < clsCard.length; i++) {
          clsCard[i].classList.add("text-bg-dark");
        }
      }
      this.setState({ Menu: Menu, Group: Group, iconTheme: ColorTheme == "light" ? "fas fa-moon" : "fas fa-sun" });
    }

    handleColaps() {
      document.getElementById("sidebar").style.display = "block";
      document.getElementById("sidebar").classList.toggle("hide");
    }

    handlePilihMenu(Menu, Title) {
      let clsMenu = document.getElementsByClassName("menu");
      for (let i = 0; i < clsMenu.length; i++) clsMenu[i].classList.remove("active");
      document.getElementById('menu' + Menu).classList.add("active");
      document.title = Title;
    }

    handleLogout() {
      localStorage.clear();
      window.location.reload();
    }

    handleChangeSetting(obj, val) {
      let detail = this.state.detailSetting;
      detail[obj] = val;
      this.setState({ detailSetting: detail });
    }

    async handleSettingProfile() {
      let sql = await api("setting", { act: "setting profile" }, true);
      if (sql.status == "sukses") {
        this.setState({
          detailSetting: sql.data,
          clsLoading: "fas fa-save",
          btnSubmit: false
        });
        let cls = document.getElementsByClassName("div-setting");
        for (let i = 0; i < cls.length; i++) {
          if (cls[i].id == "settingProfile") {
            cls[i].classList.remove("d-none");
          } else {
            cls[i].classList.add("d-none");
          }
        }
      }
    }

    openFile(e, obj) {
      let files = e.target.files;
      let detailSetting = this.state.detailSetting;
      detailSetting[obj] = imgUpload;
      this.setState({ detailSetting: detailSetting });
      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader();
        let file = files[i];
        let fileExtension = file.name.split('.').pop().toLowerCase();
        let fileSizeKB = file.size / 1024;
        reader.onload = async () => {
          try {
            let baru = fileSizeKB > 600 ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
            let img = new Image();
            img.src = baru;
            img.onload = async () => {
              let width = img.width;
              let height = img.height;
              let newRes = fileSizeKB > 600 ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
              detailSetting[obj] = newRes;
              this.setState({ detailSetting: detailSetting });
            };
          } catch (error) {
            console.error('Error compressing image:', error.message);
          }
        };
        reader.readAsDataURL(file);
      }
    }

    handleChangeTheme() {
      let ColorTheme = localStorage.getItem("ColorTheme") || "light";
      if (ColorTheme == "light") {
        localStorage.setItem("ColorTheme", "dark");
        this.setState({ iconTheme: "fas fa-sun" });
      } else {
        localStorage.setItem("ColorTheme", "light");
        this.setState({ iconTheme: "fas fa-moon" });
      }
      document.body.classList.toggle('dark-theme');
      let cls = document.getElementsByClassName("table");
      for (let i = 0; i < cls.length; i++) {
        cls[i].classList.toggle("table-dark")
      }
    }

    handleCariMenu(val) {
      if (val !== "") {

      } else {
        let Menu = this.state.Menu, Group = [
          { Nama: "Konten", Icon: "fas fa-images", Posisi: "konten", Active: false },
          { Nama: "Master", Icon: "fas fa-th-list", Posisi: "master", Active: false }
        ];
        for (let menu of Menu) {
          Menu.push(menu);
          for (let gg in Group) if (Group[gg].Posisi == menu.Posisi) Group[gg].Active = true;
        }
      }
    }

    render() {
      return (
        <Router>
          <div className="wrapper">
            <nav id="sidebar" className="sidebar">
              <div className="sidebar-header cursor">
                <img src={LogoPanjang} width="90%" className="logo-panjang" />
                <img src={Logo} width="90%" className="logo" />
              </div>
              <div className="transbox">
                <input type="search" name="qMenu" id="qMenu" className="form-control form-control-sm" placeholder="[F2] Cari Menu . . ."
                  onFocus={(e) => e.target.select()} autoComplete="off" />
                <ul className="list-unstyled components" id="ulMenu">
                  {
                    this.state.Menu.map((li, i) => {
                      if (li.Posisi == "root") {
                        return (
                          <li className="menu" id={"menu" + li.App} key={li.App} onClick={() => this.handlePilihMenu(li.App, li.Nama)}>
                            <Link to={`/${li.App}`}>
                              <i className={li.Icon}></i> <span className="nama-menu">{li.Nama}</span>
                            </Link>
                          </li>
                        )
                      }
                    })
                  }
                  {
                    this.state.Group.map((ul, i) => {
                      if (ul.Posisi != "root" && ul.Active == true) {
                        return (
                          <li key={i}>
                            <a href={`#GroupMenu${ul.Posisi}`} data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle" key={ul.Posisi}>
                              <i className={ul.Icon}></i>
                              <span className="nama-menu">{ul.Nama}</span>
                            </a>
                            <ul className="collapse list-unstyled" id={`GroupMenu${ul.Posisi}`}>
                              {
                                this.state.Menu.map((li, ii) => {
                                  if (li.Posisi == ul.Posisi) {
                                    return (
                                      <li className="menu" id={"menu" + li.App} key={li.App} onClick={() => this.handlePilihMenu(li.App, li.Nama)}>
                                        <Link to={`/${li.App}`}>
                                          {li.Nama}
                                        </Link>
                                      </li>)
                                  }
                                })
                              }
                            </ul>
                          </li>
                        )
                      }
                    })
                  }
                </ul>
              </div>
            </nav >
            <div id="content">
              <nav className="navbar">
                <div className="container-fluid d-flex nav-container">
                  <button type="button" id="sidebarCollapse" className="btn btn-sm nav-menu" onClick={() => this.handleColaps()}> <i className="fas fa-bars"></i> </button>
                  <div className="d-flex justify-content-end align-items-center nav-menu-items">
                    <button type="button" id="btnSetting" className="btn align-items-center nav-menu" data-bs-toggle="offcanvas" data-bs-target="#divSetting" aria-controls="offcanvasScrolling">
                      <i className="fas fa-cog"></i>
                    </button>
                    <button type="button" className="btn align-items-center nav-menu" style={{ position: "relative" }} onClick={() => this.handleChangeTheme()}>
                      <i className={this.state.iconTheme}></i>
                    </button>
                    <div className="dropdown ">
                      <button className="dropdown-toggle nav-profile" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="nav-profile-photo">
                          <img src={Profile} />
                        </div>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end shadow-lg dropdown-menu-dark" style={{ minWidth: "14rem", fontSize: "0.9rem", maxHeight: "440px", overflowY: "auto" }}>
                        <li className="dropdown-item">
                          <a><b>Login Sebagai :</b></a>
                          <br></br>
                          <p className="mt-2 d-flex align-items-end">
                            <i className="material-icons">person</i>
                            <span>{this.state.NamaUser}</span>
                          </p>
                        </li>
                        <li>
                          <hr className="dropdown-divider"></hr>
                        </li>
                        <li><a className="dropdown-item" style={{ fontSize: "0.8rem" }} href="#"><b>Cabang Saat Ini :</b><span>{this.state.NamaLokasi} </span></a></li>
                        <li>
                          <hr className="dropdown-divider"></hr>
                        </li>
                        <li><a className="dropdown-item" href="#"><b>Ganti Cabang:</b></a></li>
                        <li className="px-2 py-1">
                          <input id="edtSearchLokasi2" type="search" placeholder="Cari Lokasi" className="searchLokasi form-control" autoComplete="off" />
                        </li>
                        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                          <li>
                            <a className="dropdown-item item-search-lokasi item-search-lokasi-2" style={{ fontSize: "0.8rem" }} aria-current="true" data-val='<?php print $LOKASI->ID; ?>' href="#">{this.state.NamaLokasi}</a>
                          </li>
                        </div>
                        <li>
                          <hr className="dropdown-divider"></hr>
                        </li>
                        <li><span onClick={() => this.handleLogout()} className="dropdown-item text-center"><i className="material-icons">directions_run</i> Keluar</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
              <div style={{ height: "fit-content", border: "none" }} id="tampil">
                <Routes>
                  <Route path="/" element={<Dashboard page="Home" />} />
                  <Route path="/dashboard" element={<Dashboard page="Home" />} />
                  <Route path="/KontenBeranda" element={<KontenHome />} />
                  <Route path="/KontenProfile" element={<KontenProfile />} />
                  <Route path="/KontenKelas" element={<KontenKelas />} />
                  <Route path="/KontenKontak" element={<KontenKontak />} />
                  <Route path="/KontenGaleri" element={<KontenGaleri />} />
                  <Route path="/KontenFooter" element={<KontenFooter />} />
                  <Route path="/KontenArtikel" element={<KontenArtikel />} />
                  <Route path="/MasterAdmin" element={<MasterAdmin />} />
                  <Route path="/MasterGuru" element={<MasterGuru />} />
                  <Route path="/MasterMurid" element={<MasterMurid />} />
                  <Route path="/MasterWaliMurid" element={<MasterWaliMurid />} />
                  <Route path="/MasterKelas" element={<MasterKelas />} />
                  <Route path="/MasterJurusan" element={<MasterJurusan />} />
                  <Route path="/MasterMataPelajaran" element={<MasterMataPelajaran />} />
                  <Route path="/MenuArtikel" element={<MenuArtikel />} />
                  <Route path="/MenuKategoriArtikel" element={<MenuKategoriArtikel />} />
                  <Route path="/MenuKomentarArtikel" element={<MenuKomentarArtikel />} />
                  <Route path="/MasterTeam" element={<MasterTeam />} />
                  <Route path="/MenuMasterProduk" element={<MasterProduk />} />
                </Routes>
              </div>
              <div id="divPesan"></div>
            </div>
          </div>

          <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabIndex="-1" id="divSetting" aria-labelledby="offcanvasWithBothOptionsLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Setting</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <div id='settingCard' className='div-setting'>
                <div className="card mb-3 cursor" onClick={() => this.handleSettingProfile()}>
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img src={settingProfile} className="img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">Setting Profile</h5>
                        <p className="card-text">Setting untuk profil service yang sedang aktif.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='settingProfile' className='div-setting d-none'>
                <form className='needs-validation' onSubmit={(e) => submitForm(e, { crud: "setting" })} noValidate >
                  <input type='hidden' name="ID" value={this.state.detailSetting.ID} />
                  <input type='hidden' name="act" value="edit profile" />
                  <input type='hidden' name="Logo" id="edtLogo" value={this.state.detailSetting.Logo} />
                  <input type='hidden' name="LogoPanjang" id="edtLogoPanjang" value={this.state.detailSetting.LogoPanjang} />
                  <div className='formm-group'>
                    <label>Nama Service</label>
                    <input type='text' className='form-control' name='Nama' value={this.state.detailSetting.Nama} onChange={(e) => this.handleChangeSetting("Nama", e.target.value)} required />
                    <div className='invalid-feedback'>Data harus diisi</div>
                  </div>
                  <div className='formm-group'>
                    <label>Telp</label>
                    <input type='number' className='form-control' name='Telp' value={this.state.detailSetting.Telp} onChange={(e) => this.handleChangeSetting("Telp", e.target.value)} required />
                    <div className='invalid-feedback'>Data harus diisi</div>
                  </div>
                  <div className='formm-group'>
                    <label>Email</label>
                    <input type='email' className='form-control' name='Email' value={this.state.detailSetting.Email} onChange={(e) => this.handleChangeSetting("Email", e.target.value)} required />
                    <div className='invalid-feedback'>Data harus diisi</div>
                  </div>
                  <div className='formm-group'>
                    <label>Alamat</label>
                    <textarea className='form-control' name='Alamat' value={this.state.detailSetting.Alamat} onChange={(e) => this.handleChangeSetting("Alamat", e.target.value)} required />
                    <div className='invalid-feedback'>Data harus diisi</div>
                  </div>
                  <div className='formm-group'>
                    <label>Note</label>
                    <textarea className='form-control' name='Keterangan' value={this.state.detailSetting.Keterangan} onChange={(e) => this.handleChangeSetting("Keterangan", e.target.value)} required />
                    <div className='invalid-feedback'>Data harus diisi</div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 mb-2'>
                      <div className='form-group'>
                        <label>Logo Kecil</label>
                        <label>
                          <input id="fileFotoKecil" type='file' accept='image/*' className='d-none' onChange={(e) => this.openFile(e, "Logo")} />
                          <img src={this.state.detailSetting.Logo == null ? imgUpload : this.state.detailSetting.Logo} htmlFor="fileFotoKecil" style={{ width: "50%" }} />
                        </label>
                      </div>
                    </div>
                    <div className='col-md-6 mb-2'>
                      <div className='form-group'>
                        <label>Logo Panjang</label>
                        <label>
                          <input id="fileFotoPanjang" type='file' accept='image/*' className='d-none' onChange={(e) => this.openFile(e, "LogoPanjang")} />
                          <img src={this.state.detailSetting.LogoPanjang == null ? imgUpload : this.state.detailSetting.LogoPanjang} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 mb-2'>
                      <div className='form-group'>
                        <label>Warna Utama</label>
                        <input type='color' name="ColorDefault" className='form-control' value={this.state.detailSetting.ColorDefault} onChange={(e) => this.handleChangeSetting("ColorDefault", e.target.value)} />
                      </div>
                    </div>
                    <div className='col-md-6 mb-2'>
                      <div className='form-group'>
                        <label>Warna Kedua</label>
                        <input type='color' name="ColorSecondary" className='form-control' value={this.state.detailSetting.ColorSecondary} onChange={(e) => this.handleChangeSetting("ColorSecondary", e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <p></p>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.detailSetting.Status} onChange={(e) => this.handleChangeSetting("Status", e.target.checked == true ? 1 : 0)} />
                    <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                  </div>
                  <p></p>
                  <div className='d-flex gap-2'>
                    <button type='button' className='btn w-50 btn-danger'>Batal</button>
                    <button type='submit' className='btn w-50 btn-primary' disabled={this.state.btnSubmit}><i className={this.state.clsLoading}></i> Simpan</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Router >
      )
    }
  }

  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Email: "",
        Pwd: "",
        clsLogin: "",
        btnLogin: false
      };
    }

    async handleLogin(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.checkValidity()) {
        this.setState({ btnLogin: true, clsLogin: "spinner-border spinner-border-sm text-light" });
        let data = new FormData(e.target);
        fetch(host + "login", {
          method: 'POST',
          body: data,
        }).then(response => response.json()).then(hasil => {
          if (hasil.status === "sukses") {
            localStorage.setItem("Token", hasil.token);
            window.location.reload();
          } else {
            this.setState({ btnLogin: false, clsLogin: "" });
            pesan("Login Gagal", hasil.pesan, "primary");
          }
        }).catch((error) => {
          this.setState({ btnLogin: false, clsLogin: "" });
          console.log("Error: " + error);
        });
      } else {
        let forms = document.getElementsByClassName('needs-validation');
        let ululu = Array.prototype.filter.call(forms, function (form) {
          form.classList.add('was-validated');
        });
      }
    }

    handleVisible(e) {
      let cls = e.target;
      let edtPwd = document.getElementById('edtPwd');
      if (edtPwd.type == "password") {
        cls.classList.remove("fa-eye-slash");
        cls.classList.add("fa-eye");
        edtPwd.type = "text";
      } else {
        cls.classList.add("fa-eye-slash");
        cls.classList.remove("fa-eye");
        edtPwd.type = "password";
      }
    }

    render() {
      return (
        <section>
          <div className="row">
            <div className="col-md-8">
              <div className="div-img-top">
                <img src={LogoPanjang} className="logo" alt="Logo" />
              </div>
              <div className="card-form">
                <form id="formLogin" className="needs-validation" onSubmit={(e) => this.handleLogin(e)} noValidate>
                  <h4>Login</h4>
                  <h6>Selamat datang kembali di NaylaTools Software</h6>
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" name="Email" className="form-control form-control-lg" id="edtEmail" value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} required />
                    <div className="invalid-feedback">Silahkan masukan alamat email</div>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-group">
                      <input type="password" name="Password" className="form-control form-control-lg" id="edtPwd" value={this.state.Pwd} onChange={(e) => this.setState({ Pwd: e.target.value })} required />
                      <div className="invalid-feedback">Silahkan masukan password</div>
                      <i className="fas fa-eye-slash btn-opsi" id="btnVisibility" onClick={(e) => this.handleVisible(e)}></i>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end" style={{ marginBottom: "24px" }}>
                    <span className="btn-link" style={{ fontWeight: "bold", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#modal">Lupa Password?</span>
                  </div>
                  <button className=" btn btn-lg w-100 btn-login" id="btnLogin">
                    <i id="lblLoading" className={this.state.clsLogin}></i> Login
                  </button>
                  <div style={{ textAlign: "center", marginTop: "24px" }}>
                    <span>Belum mempunyai akun? <a href="https://www.naylatools.com/" className="btn-link" style={{ fontWeight: "bold", cursor: "pointer" }}>Lakukan Pendaftaran</a></span>
                  </div>
                  <div className="alert alert-danger" id="lblNotif" style={{ display: "none", marginTop: "1rem" }} role="alert"></div>
                </form>
              </div>
            </div>
            <div className="col-md-4" style={{ padding: "0px" }}>
              <div className="bg-side">
                <img src={BG2} className="img-side-bottom"></img>
              </div>
            </div>
            <div id='divPesan'></div>
          </div>

          <div className="modal fade" id="modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <form id="formForgot" className="needs-validation" noValidate>
                  <div className="modal-header" id="modalHeader">
                    <h5 className="modal-title" id="exampleModalLabel">Lupa Password</h5>
                    <button type="button" className="btn btn-close-modal" data-bs-dismiss="modal" id="dismissModal">
                      <span className="material-icons">close</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <input type="hidden" name="act" value="request" />
                    <div className="form-group">
                      <label>Alamat Email</label>
                      <input type="email" name="email" className="form-control form-control-lg" id="edtEmailForgot" placeholder="Silahkan masukan alamat email anda" required />
                      <div className="invalid-feedback">Silahkan Masukan Alamat Email</div>
                    </div>
                    <p></p>
                    <div className="alert alert-danger" id="divAlert" style={{ display: "none" }} role="alert"></div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-login" id="btnForgot"><i id="lblDaftar" disabled></i> Kirim Permintaan</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }

  if (Token) {
    return <Main />
  } else {
    return <Login />
  }
}

export default App;
